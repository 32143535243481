import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, styled } from '@mui/material';
import { moneyFormat } from 'helpers/numberFormat';
import useTableOrder from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { CustomerFinancialTicket } from 'types/financialReportList';
import { usePagination } from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import NoData from 'components/no-data/NoData';

const Container = styled('div')({
  gap: 10,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: 20,
  background: '#fff',
  overflow: 'auto',
  height: 650,
});

const Row = styled('li')(({ theme }) => ({
  '&.header': {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    background: '#fff',
  },
  display: 'grid',
  columnGap: 10,
  borderBottom: '1px solid #eee',
  paddingBottom: 6,
  gridTemplateColumns: '100px 115px 105px 95px 105px 90px 90px',
  '&.hideReceived': {
    gridTemplateColumns: '100px 115px 105px 105px 90px 90px',
  },
  '& > span': {
    gap: 5,
    fontSize: 11,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  '& > .numericData': {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  '& .item-name': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1.5fr 1fr 1fr 1fr 1fr 1fr 1fr',
    '&.hideReceived': {
      gridTemplateColumns: '1.5fr 1fr 1fr 1fr 1fr 1fr',
    },
  },
}));

const Title = styled('div')({
  position: 'sticky',
  top: 0,
  right: 0,
  left: 0,
  background: '#fff',
});

const List = styled('ul')({
  display: 'grid',
  rowGap: 10,
});

interface TopCustomersTicketProps {
  data: CustomerFinancialTicket[];
  handleClickCnpjField(cnpj: string): void;
  title?: string;
  showReceived?: boolean;
  showDocument?: boolean;
}

const TopCustomersTicket: React.FC<TopCustomersTicketProps> = ({
  data,
  title,
  handleClickCnpjField,
  showReceived = true,
  showDocument = true,
}) => {
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<CustomerFinancialTicket[]>([]);
  const { rowsPerPage, page } = usePagination();

  useEffect(() => {
    setFiltered(data);
  }, [data]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <Container>
      {title && (
        <Title>
          <Typography fontSize={13} variant="subtitle2" align="center">
            {title}
          </Typography>
        </Title>
      )}
      {data.length > 0 ? (
        <List>
          <Row className={!showReceived ? 'hideReceived header' : 'header'}>
            <span>
              <Typography fontSize={13} onClick={() => handleSort('nome')} variant="subtitle2">
                Nome
              </Typography>
              {orderedIndex.index === 'nome' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            {showDocument && (
              <span>
                <Typography fontSize={13} onClick={() => handleSort('cnpj_cpf')} variant="subtitle2">
                  CNPJ
                </Typography>
                {orderedIndex.index === 'cnpj_cpf' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>
            )}

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('limite_credito')} align="right" variant="subtitle2">
                Limite crédito
              </Typography>
              {orderedIndex.index === 'limite_credito' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            {showReceived && (
              <span className="numericData">
                <Typography fontSize={13} onClick={() => handleSort('pagos')} variant="subtitle2" align="right">
                  Recebidos
                </Typography>
                {orderedIndex.index === 'pagos' && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </span>
            )}

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('valor_total')} align="right" variant="subtitle2">
                Em aberto
              </Typography>
              {orderedIndex.index === 'valor_total' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('vencidos')} variant="subtitle2" align="right">
                Em atraso
              </Typography>
              {orderedIndex.index === 'vencidos' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>

            <span className="numericData">
              <Typography fontSize={13} onClick={() => handleSort('a_receber')} variant="subtitle2" align="right">
                A receber
              </Typography>
              {orderedIndex.index === 'a_receber' && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </span>
          </Row>
          {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
            <Row className={!showReceived ? 'hideReceived' : ''} key={index}>
              <Tooltip title="Clique para abrir a relação de boletos">
                <Typography
                  onClick={() => handleClickCnpjField(item.cnpj_cpf)}
                  color="primary"
                  fontWeight={500}
                  className="item-name"
                  align="right"
                  variant="caption"
                >
                  {item.nome}
                </Typography>
              </Tooltip>

              {showDocument && <Typography variant="caption">{item.cnpj_cpf}</Typography>}
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.limite_credito)}
              </Typography>
              {showReceived && (
                <Typography className="numericData" align="right" variant="caption">
                  {moneyFormat(item.pagos)}
                </Typography>
              )}
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.valor_total)}
              </Typography>
              <Typography className="numericData" color="error" align="right" variant="caption">
                {moneyFormat(item.vencidos)}
              </Typography>
              <Typography className="numericData" align="right" variant="caption">
                {moneyFormat(item.a_receber)}
              </Typography>
            </Row>
          ))}
        </List>
      ) : (
        <NoData message="Conteúdo indisponível" />
      )}
      <Pagination count={data.length} />
    </Container>
  );
};

export default TopCustomersTicket;
