import { createContext, useContext } from 'react';
import { BranchFinancialTicket, CustomerFinancialTicket, FinancialTicket } from 'types/financialReportList';
import { FinancialTicketFilterParams } from '../FinancialTicketReport';
import { BillSetting } from 'types/billSetting';

type FinancialTicketContextValue = {
  loading: boolean;
  selectedTicketsExpired: CustomerFinancialTicket[];
  selectAllTicketsExpired: boolean;
  handleSelectAllTicketsExpired(): void;
  handleToggleTicketsExpired(ticket: CustomerFinancialTicket): void;
  handleToggle(ticket: FinancialTicket): void;
  handleSelectAll(): void;
  selectAll: boolean;
  agroupedBranchesTicket: BranchFinancialTicket[];
  agroupedCustomersTicket: CustomerFinancialTicket[];
  agroupedCustomersTicketExpired: CustomerFinancialTicket[];
  selectedTicket: FinancialTicket | null;
  handleClickCnpjField(document: string): void;
  setSelectedTicket(partner: FinancialTicket | null): void;
  handleChangeFilter(index: keyof FinancialTicketFilterParams, value: any): void;
  filter: FinancialTicketFilterParams;
  settings: BillSetting[];
  selectedTickets: FinancialTicket[];
};

const FinancialTicketContext = createContext<FinancialTicketContextValue>({} as FinancialTicketContextValue);
export const FinancialTicketProvider = FinancialTicketContext.Provider;

export function useFinancialTicket(): FinancialTicketContextValue {
  const context = useContext(FinancialTicketContext);
  return context;
}
