import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  FormEvent,
  MouseEvent,
} from 'react';
import {
  AbcProduct,
  AbcProductBranch,
  AbcProductSeller,
  AbcProductSegment,
  AbcProductSubgroup,
  AbcProductUnity,
  AbcProductManufacturer,
} from 'types/abcProduct';
import { format } from 'date-fns';
import { useApp } from 'hooks/app';
import { ptBR } from 'date-fns/locale';
import { moneyFormat, percentFormat } from 'helpers/numberFormat';
import useTableOrder, { OrderIndexData } from 'hooks/tableOrder';
import { AbcCustomer, AbcNewCustomer } from 'types/abcCustomer';
import { Sale } from 'pages/reports/commercial/abc/product/modal/branch/SelectedBranchListMenu';
import { AbcCustomersFilterParams } from '../CurveAbcCustomer';
import { parsePtBRDate } from 'helpers/parsePtBRDate';

export type AbcCustomerTotalProps = {
  total: number;
  coust: number;
  mc: number;
  spiff: number;
  unitary: number;
  quantity: number;
  customer: number;
};

type AbcCustomerContextValue = {
  moreCustomer: AbcCustomer | null;
  setMoreCustomer: Dispatch<SetStateAction<AbcCustomer | null>>;
  moreNewCustomer: AbcNewCustomer | null;
  setMoreNewCustomer: Dispatch<SetStateAction<AbcNewCustomer | null>>;
  moreUnity: AbcProductUnity | null;
  setMoreUnity: Dispatch<SetStateAction<AbcProductUnity | null>>;
  moreSegment: AbcProductSegment | null;
  setMoreSegment: Dispatch<SetStateAction<AbcProductSegment | null>>;
  moreSubgroup: AbcProductSubgroup | null;
  setMoreSubgroup: Dispatch<SetStateAction<AbcProductSubgroup | null>>;
  moreBranch: AbcProductBranch | null;
  setMoreBranch: Dispatch<SetStateAction<AbcProductBranch | null>>;
  moreProduct: AbcProduct | null;
  setMoreProduct: Dispatch<SetStateAction<AbcProduct | null>>;
  filteredSegments: AbcProductSegment[];
  handleSortSegment(index: string): void;
  filteredProducts: AbcProduct[];
  handleSort(index: string): void;
  filteredUnits: AbcProductUnity[];
  handleSortUnity(index: string): void;
  filteredBranches: AbcProductBranch[];
  handleSortBranch(index: string): void;
  filteredSubgroups: AbcProductSubgroup[];
  handleSortSubgroup(index: string): void;
  filteredSellers: AbcProductSeller[];
  handleSortSeller(index: string): void;
  filteredCustomers: AbcCustomer[];
  handleSortCustomer(index: string): void;
  filteredNewCustomers: AbcNewCustomer[];
  handleSortNewCustomer(index: string): void;
  moreSeller: AbcProductSeller | null;
  setMoreSeller: Dispatch<SetStateAction<AbcProductSeller | null>>;
  filteredManufacturers: AbcProductManufacturer[];
  handleSortManufacturer(index: string): void;
  moreManufacturer: AbcProductManufacturer | null;
  setMoreManufacturer: Dispatch<SetStateAction<AbcProductManufacturer | null>>;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  searchText: string;
  handleSearch(searchValue: string): void;
  handleSearchInputChange(value: string): void;
  handleSearchSubmit(): void;
  loading: boolean;
  formattedTotal: AbcCustomerTotalProps;
  orderedIndex: OrderIndexData;
  selectedSale: Sale | null;
  setSelectedSale: Dispatch<SetStateAction<Sale | null>>;
  handleReprintSale(branch: number, sale: number): void;
};

const AbcCustomerContext = createContext<AbcCustomerContextValue>({} as AbcCustomerContextValue);

export function useAbcCustomers(): AbcCustomerContextValue {
  const context = useContext(AbcCustomerContext);
  return context;
}

interface CustomerProviderProps {
  filter: AbcCustomersFilterParams;
  children: React.ReactNode;
}

const CustomerProvider: React.FC<CustomerProviderProps> = ({ children, filter }) => {
  const { h2iApi } = useApp();
  const { windowWidth, isMobile, isOpenedMenu, handleOpenMenu } = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [units, setUnits] = useState<AbcProductUnity[]>([]);
  const [products, setProducts] = useState<AbcProduct[]>([]);
  const [sellers, setSellers] = useState<AbcProductSeller[]>([]);
  const [customers, setCustomers] = useState<AbcCustomer[]>([]);
  const [newCustomers, setNewCustomers] = useState<AbcNewCustomer[]>([]);
  const [branches, setBranches] = useState<AbcProductBranch[]>([]);
  const [segments, setSegments] = useState<AbcProductSegment[]>([]);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [subgroups, setSubgroups] = useState<AbcProductSubgroup[]>([]);
  const [moreProduct, setMoreProduct] = useState<AbcProduct | null>(null);
  const [moreUnity, setMoreUnity] = useState<AbcProductUnity | null>(null);
  const [filteredUnits, setFilteredUnits] = useState<AbcProductUnity[]>([]);
  const [moreCustomer, setMoreCustomer] = useState<AbcCustomer | null>(null);
  const [moreNewCustomer, setMoreNewCustomer] = useState<AbcNewCustomer | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<AbcProduct[]>([]);
  const [moreSeller, setMoreSeller] = useState<AbcProductSeller | null>(null);
  const [moreBranch, setMoreBranch] = useState<AbcProductBranch | null>(null);
  const [filteredSellers, setFilteredSellers] = useState<AbcProductSeller[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<AbcCustomer[]>([]);
  const [filteredNewCustomers, setFilteredNewCustomers] = useState<AbcNewCustomer[]>([]);
  const [moreSegment, setMoreSegment] = useState<AbcProductSegment | null>(null);
  const [manufacturers, setManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<AbcProductBranch[]>([]);
  const [filteredSegments, setFilteredSegments] = useState<AbcProductSegment[]>([]);
  const [moreSubgroup, setMoreSubgroup] = useState<AbcProductSubgroup | null>(null);
  const [filteredSubgroups, setFilteredSubgroups] = useState<AbcProductSubgroup[]>([]);
  const [moreManufacturer, setMoreManufacturer] = useState<AbcProductManufacturer | null>(null);
  const [filteredManufacturers, setFilteredManufacturers] = useState<AbcProductManufacturer[]>([]);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(isMobile || windowWidth < 930 ? 'module' : 'list');
  const [formattedTotal, setFormattedTotal] = useState<AbcCustomerTotalProps>({
    total: 0,
    coust: 0,
    mc: 0,
    spiff: 0,
    unitary: 0,
    quantity: 0,
    customer: 0,
  });

  useEffect(() => {
    setDisplayMode(isMobile || windowWidth < 930 ? 'module' : 'list');
  }, [isMobile, windowWidth]);

  useEffect(() => {
    setFilteredManufacturers(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    setFilteredSellers(sellers);
  }, [sellers]);

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  useEffect(() => {
    setFilteredNewCustomers(newCustomers);
  }, [newCustomers]);

  useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  useEffect(() => {
    setFilteredSegments(segments);
  }, [segments]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    setFilteredBranches(branches);
  }, [branches]);

  useEffect(() => {
    setFilteredSubgroups(subgroups);
  }, [subgroups]);

  const handleSearch = useCallback(() => {
    setLoading(true);
    const formattedInitialDate = !filter.initialDate ? '' : format(filter.initialDate, 'P', { locale: ptBR });
    const formattedFinalDate = !filter.finalDate ? '' : format(filter.finalDate, 'P', { locale: ptBR });

    async function loadProducts() {
      if (!h2iApi) return;

      await h2iApi
        .get(`/api/relcurvaabcprodutos`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(',') || '',
            filtro: '',
            cliente: searchText,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
          },
        })
        .then(response => {
          const _response = response.data[0];
          const produtos: AbcProduct[] = _response.produtos;

          const total = produtos.reduce((sum, item) => sum + item.total, 0);
          const coust = produtos.reduce((sum, item) => sum + item.custo, 0);
          const mc = produtos.reduce((sum, item) => sum + item.mc, 0);
          const spiff = produtos.reduce((sum, item) => sum + item.spiff, 0);
          const unitary = produtos.reduce((sum, item) => sum + item.unitario, 0);
          const quantity = produtos.reduce((sum, item) => sum + item.qtd, 0);
          setFormattedTotal(state => ({
            ...state,
            total,
            coust,
            mc,
            spiff,
            quantity,
            unitary,
          }));

          const _units = _response.unidade.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setUnits(_units);

          const _segments = _response.segmento.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSegments(_segments);

          const _sellers = _response.pedido_por.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSellers(_sellers);

          const _manufacturers = _response.fabricante.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setManufacturers(_manufacturers);

          const _subgroups = _response.subgrupo.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setSubgroups(_subgroups);

          const _branches = _response.filial.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setBranches(_branches);

          const _products = produtos.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedBilling = percentFormat((item.total / total) * 100);
            item.billing = (item.total / total) * 100;

            return item;
          });

          setProducts(_products);
        });
    }

    async function loadCustomers() {
      if (!h2iApi) return;

      await h2iApi
        .get(`/api/relcurvaabccliente`, {
          params: {
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(',') || '',
            cliente: searchText,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
          },
        })
        .then(response => {
          const _customers = response.data.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedRegisterDate = parsePtBRDate(item.data_cadastro);

            return item;
          });

          setCustomers(_customers);

          setFormattedTotal(state => ({
            ...state,
            customer: _customers.length,
          }));
        });
    }

    async function loadNewCustomers() {
      if (!h2iApi) return;

      await h2iApi
        .get(`/api/relcurvaabccliente`, {
          params: {
            novos_clientes: 'S',
            data_ini: formattedInitialDate,
            data_fim: formattedFinalDate,
            id_filial: filter.selectedBranchId || '',
            id_subgrupo: filter.subGroupIds.join(',') || '',
            cliente: searchText,
            tipo_venda: filter.typeSale,
            id_categoria: filter.categoryIds.join(','),
            id_subcategoria: filter.subCategoryIds.join(','),
            pedido_por: filter.madeBy,
            id_segmento: filter.segmentIds.join(','),
            id_unidade: filter.unitIds.join(','),
            id_fabricante: filter.manufacturerIds.join(','),
          },
        })
        .then(response => {
          const _customers = response.data.map(item => {
            item.formattedTotal = moneyFormat(item.total);
            item.formattedCoust = moneyFormat(item.custo);
            item.formattedMc = moneyFormat(item.mc);
            item.formattedSpiff = moneyFormat(item.spiff);
            item.formattedUnitary = moneyFormat(item.unitario);
            item.formattedRegisterDate = parsePtBRDate(item.data_cadastro);

            return item;
          });

          setNewCustomers(_customers);
        });
    }

    Promise.all([loadCustomers(), loadProducts(), loadNewCustomers()])
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [filter, h2iApi, searchText]);

  function handleSortSeller(index: string) {
    const filtered = sort(index, filteredSellers);
    setFilteredSellers(filtered);
  }

  function handleSortNewCustomer(index: string) {
    const filtered = sort(index, filteredNewCustomers);
    setFilteredNewCustomers(filtered);
  }

  function handleSortCustomer(index: string) {
    const filtered = sort(index, filteredCustomers);
    setFilteredCustomers(filtered);
  }

  function handleSortManufacturer(index: string) {
    const filtered = sort(index, filteredManufacturers);
    setFilteredManufacturers(filtered);
  }

  function handleSortUnity(index: string) {
    const filtered = sort(index, filteredUnits);
    setFilteredUnits(filtered);
  }

  function handleSortSegment(index: string) {
    const filtered = sort(index, filteredSegments);
    setFilteredSegments(filtered);
  }

  function handleSortBranch(index: string) {
    const filtered = sort(index, filteredBranches);
    setFilteredBranches(filtered);
  }

  function handleSortSubgroup(index: string) {
    const filtered = sort(index, filteredSubgroups);
    setFilteredSubgroups(filtered);
  }

  function handleSort(index: string) {
    const filtered = sort(index, filteredProducts);
    setFilteredProducts(filtered);
  }

  function handleSearchSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (isOpenedMenu) {
      handleOpenMenu();
    }

    handleSearch();
  }

  function handleSearchInputChange(value: string) {
    setSearchText(value);
  }

  function handleReprintSale(branch: number, sale: number, e?: MouseEvent<HTMLDivElement>) {
    e?.preventDefault();
    if (!h2iApi) {
      return;
    }

    h2iApi
      .get(`/api/imprimevenda`, {
        params: {
          id_filial: branch,
          numero_venda: sale,
        },
      })
      .then(response => setSelectedSale(response.data[0].venda[0]))
      .catch(err => console.error(err));
  }

  return (
    <AbcCustomerContext.Provider
      value={{
        moreNewCustomer,
        setMoreNewCustomer,
        filteredNewCustomers,
        handleSortNewCustomer,
        setSelectedSale,
        moreCustomer,
        setMoreCustomer,
        moreUnity,
        setMoreUnity,
        moreSegment,
        setMoreSegment,
        moreSubgroup,
        setMoreSubgroup,
        moreBranch,
        setMoreBranch,
        moreProduct,
        setMoreProduct,
        displayMode,
        setDisplayMode,
        filteredBranches,
        handleSortBranch,
        filteredProducts,
        handleSort,
        filteredSegments,
        handleSortSegment,
        filteredSubgroups,
        handleSortSubgroup,
        filteredUnits,
        handleSortUnity,
        filteredSellers,
        handleSortSeller,
        filteredCustomers,
        handleSortCustomer,
        moreSeller,
        setMoreSeller,
        filteredManufacturers,
        handleSortManufacturer,
        moreManufacturer,
        setMoreManufacturer,
        formattedTotal,
        handleSearch,
        handleSearchInputChange,
        handleSearchSubmit,
        loading,
        orderedIndex,
        searchText,
        selectedSale,
        handleReprintSale,
      }}
    >
      {children}
    </AbcCustomerContext.Provider>
  );
};

export default CustomerProvider;
