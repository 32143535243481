import { AttachMoney, BorderColor, PermIdentity, Person, Work } from '@mui/icons-material';
import { ListItemButton, Typography, styled } from '@mui/material';
import React from 'react';
import { AbcCustomerWithoutSale } from 'types/abcCustomer';

const CustomListItemButton = styled(ListItemButton)({
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
});

type CustomerItemModuleProps = {
  customer: AbcCustomerWithoutSale;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  return (
    <CustomListItemButton>
      <Person color="inherit" fontSize="small" />
      <Typography variant="subtitle2" fontSize={13}>
        {customer.cliente}
      </Typography>
      <Work color="inherit" fontSize="small" />
      <Typography variant="subtitle2" fontSize={13}>
        {customer.atividade}
      </Typography>
      <PermIdentity color="inherit" fontSize="small" />
      <Typography variant="subtitle2" fontSize={13}>
        {customer.cnpj_cpf_cliente}
      </Typography>
      <AttachMoney color="inherit" fontSize="small" />
      <Typography variant="subtitle2" fontSize={13}>
        {customer.formattedBuyValue}
      </Typography>
      <BorderColor color="inherit" fontSize="small" />
      <Typography variant="subtitle2" fontSize={13}>
        {customer.pedido_por}
      </Typography>
    </CustomListItemButton>
  );
};

export default CustomerItemModule;
